// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('active');
        }
        //else {
        //$element.removeClass('active');
        //}
    });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');


(function() {

    var s = {

        menuState: function() {

            $(this).toggleClass("cross");
            $(".main-nav-wrapper").toggleClass("open");

        },

        headerHeight: function() {

            if ($(window).scrollTop() > 0) {

                return $('.main-header').height();
            } else {

                return $('.main-header').height() - 24;
            }
        },

        headerPosition: function() {

            var top = $(window).scrollTop();

            if (top > 30) {

                $(".main-header").addClass("min");
                $(".sub-nav").addClass("min");
            } else {

                $(".main-header").removeClass("min");
                $(".sub-nav").removeClass("min");
            }
        },

        events: function() {
            $(window).scroll(this.headerPosition);
            $("#btnMobile").on("click", this.menuState);
        },

        init: function() {

            this.headerPosition();
            this.events();
        }

    };

    s.init();

})();

// ###########################################################################
// ANCRE
$('a[href^="#"]').on('click', function(event) {
        var target = $($(this).attr('href'));
        if( target.length ) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top - 200 // Ajout du décalage de 200px
            }, 1000);
        }
    });

// ###########################################################################
// PARALAX
$(document).ready(function() {
    $(window).scroll(function() {
        var scroll = $(this).scrollTop();
        $('.content-1-1 .image').css({
            'transform': 'translateY(' + scroll / 20 + 'px)' // Réglez la vitesse du parallaxe en ajustant la division
        });
        console.log('scroll');
    });
});


    $(document).ready(function(){

      $('.productList').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '35%',
        autoplay: false,
        infinite: true,
        arrows: false,
        dots:true,
        cssEase: 'ease',
        fade: false,
        speed: 900,
        responsive: [{
                breakpoint: 620,
                settings: {
                  slidesToShow: 1,
                  centerPadding: '0px',
              },
          }]
      });
    });

    $('.productList').on('beforeChange', function(event, slick, currentSlide, nextSlide){
//      console.log(nextSlide);
      var SlideNextSelected = $(slick.$slides[nextSlide]);
      var nextHue = SlideNextSelected.data('hue');
      $(".circleDecoration").attr("data-hueRotate", nextHue );
    });

    var flag = false;
    $('.productList').on('init', function(event,slick){
        if(flag != true ){
            flag = true;
            console.log(slick);
            var SlideFirst =  $(slick.$slides[0]);
            var nextHue = SlideFirst.data('hue');
            $(".circleDecoration").attr("data-hueRotate", nextHue );
        }
    });

    $("#v-betche-tab a").on('click', function(){
        var hrefAttribute =$(this).attr("href");
      var selectNextActive = $(hrefAttribute +" .slick-current");
      $(".circleDecoration").attr("data-hueRotate", selectNextActive.data('hue') );

    });


    //NUMBERS

    // COUT NUMBER ANIMATION
    // inViewport jQuery plugin
    // http://stackoverflow.com/a/26831113/383904
    // Based on Fiddle: https://jsfiddle.net/2v3mq3nh/4/
    // From this Answer: http://stackoverflow.com/a/36980280
    $(function($, win) {
      $.fn.inViewport = function(cb) {
        return this.each(function(i,el){
          function visPx(){
            var H = $(this).height(),
                r = el.getBoundingClientRect(), t=r.top, b=r.bottom;
            return cb.call(el, Math.max(0, t>0? H-t : (b<H?b:H)));
          } visPx();
          $(win).on("resize scroll", visPx);
        });
      };
    }(jQuery, window));

    jQuery(function($) { // DOM ready and $ in scope

      $(".count").inViewport(function(px) { // Make use of the `px` argument!!!
        // if element entered V.port ( px>0 ) and
        // if prop initNumAnim flag is not yet set
        //  = Animate numbers
        if(px>0 && !this.initNumAnim) {
          this.initNumAnim = true; // Set flag to true to prevent re-running the same animation
          $(this).prop('Counter',0).animate({
            Counter: $(this).text()
          }, {
            duration: 2000,
            step: function (now) {
              $(this).text(parseFloat(now.toFixed(0)));
            }
          });
        }
      });

    });
